import React from "react";
import {
  Container,
  Title,
  Content,
  DesktopPracticePhotosWrapper,
  MobilePracticePhotosWrapper,
} from "./styles";
import PageContainer from "../PageContainer";
import { Breadcrumb, BreadcrumbItem } from "../Breadcrumb";
import PracticePhotos from "../practice-photos";
const OurPractice = () => {
  return (
    <>
      <Breadcrumb pageTitle="Our Practice">
        <BreadcrumbItem Link="/our-practice" Text="Our Practice" />
      </Breadcrumb>

      <PageContainer>
        <Container>
          <Container>
            <Title>Welcome to Gippsland Orthodontics!</Title>
            <MobilePracticePhotosWrapper>
              <PracticePhotos />
            </MobilePracticePhotosWrapper>
            <Content>
              We pride ourselves on the ability to combine a warm and welcoming
              environment with our modern state-of-the-art facilities.
            </Content>
            <Content>
              Our team is conscious of the carbon footprint. We are proud to
              offer you the digital processes that allow us to minimise paper
              use in the clinic. We also offer the ability to digitally scan
              mouths and teeth, rather than taking conventional ‘moulds’ of
              teeth with the old-fashioned putty. This previous process was
              messy, unpleasant and not comfortable. Fortunately, at Gippsland
              Orthodontics we use up-to-date technology with a 3Shape intraoral
              scanner that uses a small, wand-like device to scan the teeth and
              jaws.
            </Content>
            <Content>
              Our facilities have been carefully designed to make you feel at
              home. You can have peace of mind that we follow the highest
              standards of infection control and hygiene with continuous
              disinfection of water lines of equipment. Our rigorous
              disinfection protocols are carefully followed and at the same time
              being environmentally friendly. We use Ozone disinfection of most
              common surfaces instead of harsh chemicals that can ultimately
              harm nature.
            </Content>
            <Content>
              Our comfort menu has been carefully selected to provide the most
              comfortable experience. We offer the ability to watch TV screens
              mounted to the roof during orthodontic appointments. You just need
              to take a seat, relax and enjoy!
            </Content>
          </Container>
          <DesktopPracticePhotosWrapper>
            <PracticePhotos />
          </DesktopPracticePhotosWrapper>
        </Container>
      </PageContainer>
    </>
  );
};

export default OurPractice;
