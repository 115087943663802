import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import OurPractice from "../components/our-practice";

const AboutOurPracticePage = () => (
  <Layout>
    <SEO title="About Our Practice" />
    <OurPractice />
  </Layout>
);

export default AboutOurPracticePage;
