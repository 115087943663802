import styled from "styled-components";
import { breakpoints } from "../../styles/variables";
import PracticePhotos from "../practice-photos";

export const Container = styled.div`
  display: block;
  margin-bottom: 2rem;
`;

export const Title = styled.h1`
  font-size: 1.3rem;
  padding-bottom: 2rem;
`;

export const Content = styled.p`
  padding-bottom: 1rem;
  line-height: 1.8rem;
  font-family: "Red Hat Display", sans-serif;
`;

export const MobilePracticePhotosWrapper = styled.div`
  display: block;
  @media (min-width: ${breakpoints.sm}) {
    display: none;
  }
`;

export const DesktopPracticePhotosWrapper = styled.div`
  display: none;
  @media (min-width: ${breakpoints.sm}) {
    display: block;
  }
`;
