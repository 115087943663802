import { useStaticQuery, graphql } from "gatsby";

const useData = () => {
  const data = useStaticQuery(graphql`
    fragment ImagePracticePhotos on File {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    query {
      about_1: file(relativePath: { eq: "about/050_Gippsland.jpg" }) {
        ...ImagePracticePhotos
      }
      about_2: file(relativePath: { eq: "about/001_Gippsland.png" }) {
        ...ImagePracticePhotos
      }
      about_3: file(relativePath: { eq: "about/055_Gippsland.jpg" }) {
        ...ImagePracticePhotos
      }
      about_4: file(relativePath: { eq: "about/018_Gippsland.jpg" }) {
        ...ImagePracticePhotos
      }
    }
  `);

  return [
    { image: data.about_1.childImageSharp.fluid },
    { image: data.about_2.childImageSharp.fluid },
    { image: data.about_3.childImageSharp.fluid },
    { image: data.about_4.childImageSharp.fluid },
  ];
};
export default useData;
